







































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import moment from "moment";

export default Vue.extend({
  data() {
    return {
      moment,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: value => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "TPKD", value: "name" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false }
      ],
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },
      tpkds: [],
      user: null
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Thêm mới" : this.$t("EDIT");
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  async created() {
    this.user = await apiClient.userGetById(this.$route.params.id);
    const { items } = await apiClient.userGetAll({
      rawOptions: {
        limit: -1
      },
      filters: [
        {
          key: "roles",
          operator: "match",
          value: "tpkd"
        }
      ]
    });
    this.tpkds = items;
  },
  methods: {
    async initialize() {
      this.loading = true;
      const items = await apiClient.getTpkdByUser(this.$route.params.id);
      this.data = items;
      this.dataCount = items.length;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign(
        {},
        {
          tpkd_id: item.id,
        }
      );
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign(
        {},
        {
          tpkd_id: item.id,
        }
      );
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.removeTpkdFromUser(
        this.$route.params.id,
        this.editedItem.tpkd_id
      );
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const result = await apiClient.addTpkdToUser(
        this.$route.params.id,
        this.editedItem.tpkd_id,
        this.editedItem
      );
      if (result) {
        this.close();
        await this.initialize();
      }
    }
  }
});
