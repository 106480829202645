var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"server-items-length":_vm.dataCount,"sort-by":"created_at","sort-desc":"","options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 50, 100, 200],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("DS TPKD "+_vm._s(_vm.user && _vm.user.name))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Thêm TPKD "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-account-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-container',[_c('v-row',[(_vm.editedIndex===-1)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.tpkds,"rules":[_vm.ruleRequired],"item-value":"id","item-text":"name","label":"TPKD","persistent-hint":""},model:{value:(_vm.editedItem.tpkd_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "tpkd_id", $$v)},expression:"editedItem.tpkd_id"}})],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("CANCEL"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.form},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("SAVE"))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("CONFIRM_DELETE"))+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("CANCEL")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("OK")))]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("DELETE"))+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("NO_DATA"))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }